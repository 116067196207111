<template>
    <div>
        <div class="main">
            <div class="title">
                <!-- 我参与的案件 -->
                <span>参加した案件</span>
            </div>
            <div class="tab-box">
                <el-table :data="tableData" border style="width: 100%">
                    <!-- 匹配度 -->
                    <!-- <el-table-column prop="similar" label="マッチング度" align="center" >
                        <template scope="scope">
                            <span>
                                {{ scope.row.similar * 100 }}%
                            </span>
                        </template>
                    </el-table-column> -->
                    <el-table-column prop="title" label="漫画タイトル" align="center">
                        <template scope="scope">
                            <span class="style" @click="toDetails(scope.row)">
                                {{ scope.row.title }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="nickname" label="作家名" align="center">
                    </el-table-column>
                    <el-table-column prop="name" align="center" label="案件名">
                    </el-table-column>
                    <!-- 最终更新 -->
                    <el-table-column prop="check_at" align="center" label="最終更新時間">
                        <template scope="scope">
                            <font> {{ scope.row.check_at || "-" }} </font>
                        </template>
                    </el-table-column>
                    <!-- 校对截止日 -->
                    <el-table-column prop="end_at" align="center" label="校了締め日">
                        <template scope="scope">
                            <font> {{ scope.row.end_at || "-" }} </font>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
import utils from "@/assets/js/utils";
import service from "@/service";
export default {
    data() {
        return {
            tableData: []
        }
    },
    mounted() {
       this.pushChapter()
    },
    methods: {
        //获取案件列表
        pushChapter() {
            service.personal.pushChapter().then(res => {
                if (res.status === 200) {
                    this.tableData = res.data
                }
            }).catch(err => {
                console.log(err)
            })
        },
        toDetails(data) {

        }
    }
}
</script>

<style lang="scss" scoped>
.form-box {
	padding: 0 40px;
	margin-top: 10px;
}

.main {
	padding: 20px 40px;

	.style {
		color: #1875d2;
		cursor: pointer;
	}

	.title {
		margin-bottom: 20px;

		span {
			color: #333;
			font-size: 15px;
			font-weight: bold;
			letter-spacing: 1px;
		}
	}

	.page-box {
		margin-top: 20px;
		display: flex;
		justify-content: flex-end;
	}
}
</style>